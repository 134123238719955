import { MeetingConsumer, MeetingProvider } from "@videosdk.live/react-sdk";
import React, { useCallback, useEffect, useState } from 'react';
import * as api from '../../api/meeting';
import { Box, BoxProps, CircularProgress } from "@mui/material";
import { MeetingAppProvider } from "./MeetingAppProvider";
import MeetingView from "./MeetingView";
import JoiningScreen from "./JoiningScreen";

/*
<MeetingConsumer>
                {() => (
                  <MeetingView meetingId={roomId} onMeetingLeave={onMeetingLeave} height='100vh' control />
                )}
              </MeetingConsumer>
*/

type Joining = BoxProps;
const Loading: React.FC<Joining> = (props) => {
  return (
    <Box width='100%' height='100vh' display='flex' justifyContent='center' alignItems='center' {...props}>
      <CircularProgress />
    </Box>
  );
}

export type MeetingWidgetProps = {
  roomId: string;
  control?: boolean;
  onMeetingLeave?: () => void;
  participantName?: string;
};

const MeetingWidget: React.FC<MeetingWidgetProps> = ({ roomId: meetingId, control, participantName, onMeetingLeave }) => {
  const [token, setToken] = useState<string | undefined>();
  useEffect(() => {
    api.getMeetingToken(meetingId)
      .then(setToken)
      .catch(console.error);
  }, [meetingId]);

  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(true);
  const [customAudioStream, setCustomAudioStream] = useState<MediaStream | undefined>(undefined);
  const [customVideoStream, setCustomVideoStream] = useState<MediaStream | undefined>(undefined);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  //const [isMeetingLeft, setIsMeetingLeft] = useState(false);

  const handleJoin = useCallback(() => {
    setMeetingStarted(true);
  }, []);

  const handleLeave = useCallback(() => {
    setMeetingStarted(false);
    onMeetingLeave?.()
  }, [onMeetingLeave]);

  if(!token) {
    return <Loading />;
  }

  if(!isMeetingStarted) {
    return (
      <JoiningScreen
        onJoin={handleJoin}
        setCustomAudioStream={setCustomAudioStream}
        setCustomVideoStream={setCustomVideoStream}
        height='100vh'
      />
    );
  }

  return (
    <MeetingProvider
      config={{
        meetingId,
        micEnabled: micOn,
        webcamEnabled: webcamOn,
        name: participantName || 'Unknown',
        multiStream: true,
        customCameraVideoTrack: customVideoStream,
        customMicrophoneAudioTrack: customAudioStream,
        debugMode: true,
      }}
      token={token}
      reinitialiseMeetingOnConfigChange={true}
      joinWithoutUserInteraction={true}
    >
      <MeetingConsumer>
        {() => (
          <MeetingView meetingId={meetingId} onMeetingLeave={handleLeave} height='100vh' control={control} />
        )}
      </MeetingConsumer>
    </MeetingProvider>
  );
};

const MeetingWidgetWithProvider: React.FC<MeetingWidgetProps> = (props) => {
  return (
    <MeetingAppProvider>
      <MeetingWidget {...props} />
    </MeetingAppProvider>
  );
}

export default React.memo(MeetingWidgetWithProvider);