import React, { useState, MouseEvent } from 'react';
import {
  Popover,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ButtonProps,
  Paper,
  MenuList,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import DropCAM from '@mui/icons-material/PhotoCamera';
import { useMeetingApp } from '../../MeetingAppProvider';
import DeviceButton from '../../shared/DeviceButton';

interface WebcamDevice {
  deviceId: string;
  label: string;
  kind: string;
}

export interface DropDownCamProps extends ButtonProps {
  webcams: WebcamDevice[];
  changeWebcam: (deviceId: string) => void;
}

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    marginTop: theme.spacing(1.5),
    width: '288px', // 72 * 4px
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginY: theme.spacing(0.5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  color: theme.palette.common.white,
  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));


const DropDownCam: React.FC<DropDownCamProps> = ({ webcams, changeWebcam, ...props }) => {
  const {
    setSelectedWebcam,
    selectedWebcam,
    isCameraPermissionAllowed,
  } = useMeetingApp();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (isCameraPermissionAllowed) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'webcam-popover' : undefined;

  const handleSelectWebcam = (deviceId: string, label: string) => {
    setSelectedWebcam({
      id: deviceId,
      label: label,
    });
    changeWebcam(deviceId);
    handleClose();
  };

  return (
    <>
      <DeviceButton
        aria-describedby={id}
        onClick={handleClick}
        disabled={!isCameraPermissionAllowed}
        isPermissionAllowed={!!isCameraPermissionAllowed}
        open={open}
        startIcon={<DropCAM />}
        endIcon={<ExpandMoreIcon />}
        {...props}
      >
        {isCameraPermissionAllowed ? selectedWebcam?.label : 'Permission Needed'}
      </DeviceButton>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Paper>
          <MenuList>
            {webcams
              .filter((webcam) => webcam.kind === 'videoinput')
              .map((webcam, index) => (
                <StyledListItemButton
                  key={`webcams_${index}`}
                  onClick={() => handleSelectWebcam(webcam.deviceId, webcam.label)}
                  selected={selectedWebcam?.label === webcam.label}
                >
                  <ListItemIcon sx={{ minWidth: 24, color: 'inherit' }}>
                    {selectedWebcam?.label === webcam.label && (
                      <CheckIcon fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={webcam.label ? webcam.label : `Webcam ${index + 1}`}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                </StyledListItemButton>
              ))}
          </MenuList>
        </Paper>
      </StyledPopover>
    </>
  );
};

export default React.memo(DropDownCam);