import React, { useEffect, useRef, useState } from "react";
import { useMeetingApp } from '../../MeetingAppProvider';
import { Button, styled } from '@mui/material';
import { Pause, PlayArrow } from '@mui/icons-material';

const mimeType = "audio/webm";

const RecordProgress = styled('div')<{ progress: number }>(({ theme, ...props }) => ({
  width: `${props.progress}%`,
  height: '100%',
  backgroundColor: '#6F767E',
  position: 'absolute',
  top: 0,
  left: 0,
}));

export type MicTestProps = {
  customAudioStream: MediaStream;
  didDeviceChange: number;
  disabled: boolean;
}

export default function MicTest({
  customAudioStream,
  didDeviceChange,
  disabled,
}: MicTestProps) {
  const { selectedSpeaker } = useMeetingApp();
  const [audioProgress, setAudioProgress] = useState(0);
  const [recordingProgress, setRecordingProgress] = useState(0)
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [recordingDuration, setRecordingDuration] = useState(0)
  
  const [audio, setAudio] = useState<string>();
  const intervalRef = useRef<any>();
  const mediaRecorder = useRef<MediaRecorder | null>(null);

  useEffect(() => {
    if (didDeviceChange) {
      if (mediaRecorder.current != null && mediaRecorder.current.state === "recording") { stopRecording() }
      setRecordingProgress(0)
      setRecordingStatus("inactive")
    }
  }, [didDeviceChange])

  useEffect(() => {
    if(disabled) {
      if (mediaRecorder.current != null && mediaRecorder.current.state === "recording") { stopRecording() }
      setRecordingProgress(0)
      setRecordingStatus("inactive")
    }
  }, [disabled])

  const handlePlaying = () => {
    if(!selectedSpeaker) return;
    setRecordingStatus("playing");
    const audioTags = document.getElementsByTagName("audio");

    for (let i = 0; i < audioTags.length; i++) {
      const audioTag = audioTags.item(i);
      if(!audioTag) return;
      audioTag.setSinkId(selectedSpeaker.id as string)
        .then(() => {
          audioTag.play();
          audioTag.addEventListener('timeupdate', () => {
            const progress = (audioTag.currentTime / recordingDuration) * 100;
            setAudioProgress(progress);
          });
          audioTag.addEventListener('ended', () => {
            setAudioProgress(0);
            setRecordingStatus("stopped recording");
          });
        })
    }
  }

  const startRecording = async () => {
    setRecordingStatus("recording");

    try {
      // @ts-ignore
      const media = new MediaRecorder(customAudioStream, { type: mimeType });
      mediaRecorder.current = media;
      mediaRecorder.current.start();
      let localAudioChunks: Blob[] = [];

      mediaRecorder.current.ondataavailable = (event) => {
        if (typeof event.data === "undefined") return;
        if (event.data.size === 0) return;
        localAudioChunks.push(event.data);
      };

      mediaRecorder.current.onstop = () => {
        const audioBlob = new Blob(localAudioChunks, { type: mimeType });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudio(audioUrl);
        localAudioChunks = []
        const elapsedTime = Date.now() - startTime;
        const durationInSeconds = (elapsedTime / 1000);
        setRecordingDuration(durationInSeconds)
      };

      const startTime = Date.now();
      intervalRef.current = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const progress = (elapsedTime / 7000) * 100;
        setRecordingProgress(progress);
      });

      setTimeout(() => {
        clearInterval(intervalRef.current)
        stopRecording();
      }, 7000)
    } catch (err) {
      console.log("Error in MediaRecorder:", err)
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state != "inactive") {
      setRecordingProgress(0);
      setRecordingStatus("stopped recording");
      clearInterval(intervalRef.current)
      mediaRecorder.current.stop();
    }
  };

  return (
    <>
      {recordingStatus === "inactive" && (
        <Button variant="contained" sx={{ marginLeft: 2, minWidth: 80 }} onClick={startRecording}>
          Record
        </Button>
      )}
      {recordingStatus === "stopped recording" && (
        <Button variant="contained" sx={{ marginLeft: 2, minWidth: 80 }} onClick={handlePlaying}>
          <PlayArrow />
        </Button>
      )}
      {recordingStatus === "recording" && (
        <Button variant="contained" sx={{ marginLeft: 2, minWidth: 80 }} onClick={stopRecording}>
          <RecordProgress progress={recordingProgress} />
          <Pause />
        </Button>
      )}
      {recordingStatus === "playing" && (
        <Button variant="contained" sx={{ marginLeft: 2, minWidth: 80 }} onClick={handlePlaying}>
          <RecordProgress progress={audioProgress} />
          <Pause />
        </Button>
      )}
      <audio src={audio} ></audio>
    </>
  )
}