import React, { useState } from 'react';
import GoldButton, { GoldButtonProps } from '../dumps/GoldButton';
import { styled } from '@mui/material';

import ExternalLink from '../dumps/ExternalLink';

export type DonateButtonProps = Omit<GoldButtonProps, 'onClick'>;
const url = 'https://www.donationalerts.com/r/pinkypupa?fbclid=PAY2xjawG391tleHRuA2FlbQIxMAABpmojwbRqYtkMHs3cQlccZQhJY82RHPaGmDaIYP4-IyNFGPtyKdD1WL_b1Q_aem_IGAeifLwGdZfOOB-26m8xQ';
const Img = styled('img')({
  width: '16px',
  height: '16px',
  marginLeft: '4px',
  marginRight: '4px',
  transform: 'translateY(-1px)',
});

const DonateButton: React.FC<DonateButtonProps> = ({ disabled, ...props }) => {
  return (
    <ExternalLink href={url}>
      <GoldButton size='large' {...props}>
        Donate <Img src='/coin.webp' alt='coin' />
      </GoldButton>
    </ExternalLink>
  );
};

export default DonateButton;