import useSWR from "swr";
import { Price } from "../types/model";
import * as api from '../api/model';

export function useModelPrices(modelId?: string) {
  const { data = [] } = useSWR<Price[]>(modelId ? api.utrls.GetPrices(modelId) : null);
  return data;
}

export function useModelPrice(modelId: string, type: string) {
  const prices = useModelPrices(modelId);
  return prices.find(price => price.id === type);
}


export default useModelPrices;