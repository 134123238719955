import React from 'react';
import { Avatar, Box, BoxProps, ListItem, ListItemIcon, ListItemText, Typography, darken } from '@mui/material';
import BlurPaper from '../dumps/BlutPaper';
import { keyframes } from '@mui/system';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { SubLevel } from '../../types/sub';
import { useSubLevels } from '../../hooks/useSubLevels';


const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

// with opacity
function level2color(level: string) {
  switch (level) {
    case '1': // dark bronze 
      return darken('#8C603A', 0.2);
    case '2': // dark silver
      return darken('#A0A0A0', 0.2);
    case '3': // dark gold
      return darken('#B8860B', 0.2);
    default:
      return undefined;
  }
}

type SubLevelSelectProps = BoxProps & {
  value?: SubLevel;
  onSelected: (box: SubLevel) => void;
  discount?: number;
  exclude?: SubLevel[];
}

const SubLevelSelect: React.FC<SubLevelSelectProps> = ({ onSelected, value, discount, exclude, ...props }) => {
  const { subLevels } = useSubLevels();
  const filtered = subLevels.filter((sl) => !exclude?.find((e) => e.id === sl.id));
  return (
    <Box {...props}>
      {filtered.map((sl, index) => (
        <BlurPaper
          key={sl.id}
          duration={`${index * 0.1}s`}
          onClick={() => onSelected(sl)}
          sx={{
            cursor: 'pointer',
            padding: 1,
            border: '1px solid transparent',
            boxShadow: value?.id === sl.id ? '0 0 0 2px gold inset' : '0 0 0 1px #ccc inset',
            transition: 'box-shadow 0.3s ease-in-out',
            animation: value?.id === sl.id ? `${bounce} 0.3s` : 'none',
            backgroundColor: level2color(sl.id),
          }}
        >
          <ListItem secondaryAction={<WhatshotIcon htmlColor='gold' />}>
            <ListItemIcon>
              <Avatar src='/coin.webp' sx={{ width: 32, height: 32 }} />
            </ListItemIcon>
            <ListItemText primary={`Level ${sl.title}`} />
            <ListItemText primary={(
              <Typography sx={{
                color: value?.id === sl.id ? 'gold' : 'inherit',
                fontWeight: value?.id === sl.id ? 'bold' : 'normal',
              }}>
                {discount ? sl.price - discount : sl.price}$ / month
              </Typography>
            )}/>
          </ListItem>
        </BlurPaper>
      ))}
    </Box>
  );
};

export default SubLevelSelect;
