import React from 'react';
import { GoldButtonProps } from '../../dumps/GoldButton';
import { useModel } from '../../providers/ModelProvider';
import { useMyModelSub } from '../../../hooks/useSubs';
import {
  Typography,
  TypographyProps,
} from '@mui/material';
import moment from 'moment';

export type SubscribeButtonProps = Omit<GoldButtonProps, 'onClick'>;

const nextChargeDate = (date: Date) =>
  moment(date).add(1, 'months').format('MMMM D, YYYY');

const NextChargeDate: React.FC<TypographyProps> = ({ ...props }) => {
  const { modelId } = useModel();
  const { sub } = useMyModelSub(modelId);
  const chargeDate = sub && sub.lastPaymentDate ? nextChargeDate(sub.lastPaymentDate) : null;
  return (
    <Typography {...props}>
      Next charge date: {chargeDate || '...'}
    </Typography>
  );
};

export default React.memo(NextChargeDate);
