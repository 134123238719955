import { Accordion, AccordionProps, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled } from '@mui/system';
import { bounceAnimationCss } from '../../lib/animations';

type AddProps = {
  duration?: string;
};

export type GoldAccordionProps = AccordionProps & AddProps;

const GoldAccordion = styled(Accordion)<AddProps>(
  ({ theme, duration = '0.15s' }) => ({
    borderRadius: '12px',
    minHeight: '56px',
    boxShadow: 'none',
    width: '100%',
    overflow: 'hidden',
    '&::before': {
      display: 'none',
    },
    ...bounceAnimationCss(duration), 
  })
);

type GoldAccordionSummaryProps = {
  color?: string;
}

const GoldAccordionSummary = styled(AccordionSummary)<GoldAccordionSummaryProps>(({ color = '#666666' }) => ({
  '& .MuiAccordionSummary-content': {
    fontSize: '20px',
    fontWeight: 700,
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#FFFFFF',
  },
  backgroundColor: color,
  borderRadius: '12px',
  minHeight: '56px',
}));

const GoldAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: 'transparent',
  paddingTop: theme.spacing(2),
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  borderRadius: '0 0 12px 12px',
  //borderBottomLeftRadius: '12px',
  //borderBottomRightRadius: '12px',
}));

export { GoldAccordion, GoldAccordionSummary, GoldAccordionDetails };
