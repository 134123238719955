import { useEffect, useState } from "react";

export const useTONRate = () => {
  const [rate, setRate] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRate = async () => {
      try {
        const response = await fetch('https://tonapi.io/v2/rates?tokens=ton&currencies=usd');
        const data = await response.json();
        const price = data.rates.TON.prices.USD;
        setRate(price);
      } catch (error) {
        console.error('Failed to get TON price in USD', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRate();
  }, []);

  return { rate, loading };
};