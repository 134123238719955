import { Typography, TypographyProps } from "@mui/material";
import { useParticipant } from "@videosdk.live/react-sdk";
import React from "react";
import MicIcon from '@mui/icons-material/Mic';

export type ParticipantMicInfoProps = TypographyProps &{
  participantId: string;
};

function ParticipantMicInfo ({ participantId, children, ...props }: ParticipantMicInfoProps) {
  const { micStream, micOn, displayName } = useParticipant(participantId);
  // green mic icon if micStream is available and red if not
  return (
    <Typography {...props}>
      {displayName || children} Mic:{" "} {micOn ? "ON" : "OFF"} <MicIcon color={micStream ? "success" : "error"} />
    </Typography>
  );
}

export default React.memo(ParticipantMicInfo);