import { useParticipant } from "@videosdk.live/react-sdk";
import React, { useCallback, useEffect, useRef } from "react";
import { useMeetingApp } from "../../MeetingAppProvider";
import { toast } from "react-hot-toast";
import { Box, Button } from "@mui/material";
import { Update } from "@mui/icons-material";

export type ParticipantAudioProps = {
  participantId: string;
};

function ParticipantAudio({ participantId }: ParticipantAudioProps) {
  const { selectedSpeaker } = useMeetingApp();
  const micRef = useRef<HTMLAudioElement>(null);
  const { micStream, micOn, isLocal } = useParticipant(participantId);
  const [error, setError] = React.useState<string | null>(null);

  useEffect(() => {
    if (!micRef.current) { return; }

    if (micOn && micStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(micStream.track);

      // set speaker
      micRef.current.srcObject = mediaStream;
      const audioTag = micRef.current;

      if(selectedSpeaker) {
        micRef.current.setSinkId(selectedSpeaker.deviceId)
          .then(() => {
            audioTag
              .play()
              .catch((error) => {
                console.error("audio.current.play() failed", error)
                toast.error("Failed to play audio");
                setError("Failed to play audio");
              });
          })
          .catch((error) => {
            console.error("setSinkId failed", error)
            toast.error("Failed to set speaker");
          });
      } else {
        audioTag
          .play()
          .catch((error) => {
            console.error("audio.current.play() failed", error)
            toast.error("Failed to play audio");
            setError("Failed to play audio");
          });
      }
    } else {
      micRef.current.srcObject = null;
    }
  }, [selectedSpeaker, micRef.current, micStream, micOn]);

  const retryPlay = useCallback(() => {
    if (micRef.current) {
      micRef.current.play()
        .then(() => setError(null))
        .catch((error) => {
          console.error("audio.current.play() failed", error)
          toast.error("Failed to play audio");
          setError("Failed to play audio");
        });
    }
  }, [micRef.current]);
  // if error then show in center of the screen button to retry
  return (
    <>
      <audio ref={micRef} autoPlay muted={isLocal} />
      {error && (
        <Box sx={{
          position: "absolute",
          top: "50%", left: "50%",
          width: "100%",
          height: "100%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "rgba(128, 0, 0, 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <Button
            startIcon={<Update />}
            onClick={retryPlay}
            color="error"
            variant='contained'
            size='large'
          >
            Retry
          </Button>
        </Box>
      )}
    </>
  );
}

export default React.memo(ParticipantAudio);