import { Avatar, Box, BoxProps, Container, IconButton, IconButtonProps, Toolbar } from "@mui/material";
import React, { useState }  from "react";
import { JoinFull } from "@mui/icons-material";
import JoiningContext from "./JoiningContext";
import CameraSelect from "./components/config/CameraSelect";
import CameraPreview from "./components/CameraPreview";
import MicSelect from "./components/config/MicSelect";
import useIsMobile from "../../hooks/useIsMobile";
import SpeakerSelect from "./components/config/SpeakerSelect";

/*
const ButtonWithTooltip = ({ onClick, onState, OnIcon, OffIcon }) => {
    const btnRef = useRef();
    return (
      <>
        <div>
          <button
            ref={btnRef}
            onClick={onClick}
            className={`rounded-full min-w-auto w-12 h-12 flex items-center justify-center 
            ${onState ? "bg-white" : "bg-red-650 text-white"}`}
          >
            {onState ? (
              <OnIcon fillcolor={onState ? "#050A0E" : "#fff"} />
            ) : (
              <OffIcon fillcolor={onState ? "#050A0E" : "#fff"} />
            )}
          </button>
        </div>
      </>
    );
  };
*/


/*
const CameraPermissionDenied = () => {
  return (
    <Box
      width='100%'
      height='100%'
      bgcolor="#000"
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h6" color="error">
        Camera Permission Denied
      </Typography>
    </Box>
  );
}
const Camera = () => {
  const {
    selectedWebcam,
    isCameraPermissionAllowed,
    setIsCameraPermissionAllowed,
  } = useMeetingApp();

  return isCameraPermissionAllowed ? (
    <ButtonWithTooltip
      onClick={_toggleWebcam}
      onState={webcamOn}
      mic={false}
      OnIcon={WebcamOnIcon}
      OffIcon={WebcamOffIcon}
    />
  ) : (
    <CameraPermissionDenied />
  );
}
*/

const goodGreenColor = '#4caf50';
type JoinButtonProps = IconButtonProps;
const JoinButton: React.FC<JoinButtonProps> = (props) => {
  return (
    <IconButton {...props}>
      <Avatar sx={{ width: 64, height: 64, backgroundColor: goodGreenColor }}>
        <JoinFull />
      </Avatar>
    </IconButton>
  );
}

type JoiningScreenProps = BoxProps & {
  onJoin: () => void;
  setCustomAudioStream: React.Dispatch<React.SetStateAction<MediaStream | undefined>>;
  setCustomVideoStream: React.Dispatch<React.SetStateAction<MediaStream | undefined>>;
};

function JoiningScreen({
  onJoin,
  setCustomAudioStream,
  setCustomVideoStream,
  ...props
}: JoiningScreenProps) {
  const [micOn, setMicOn] = useState(true);
  const [audioTrack, setAudioTrack] = useState<MediaStreamTrack | null>(null);

  const [webcamOn, setWebcamOn] = useState(true);
  const [videoTrack, setVideoTrack] = useState<MediaStreamTrack | null>(null);

  const [speakerOn, setSpeakerOn] = useState(true);


  const isMobile = useIsMobile();

  return (
    <JoiningContext.Provider
      value={{
        micOn, setMicOn,
        audioTrack, setAudioTrack,
        webcamOn, setWebcamOn,
        videoTrack, setVideoTrack,
        speakerOn, setSpeakerOn
      }}
    >
      <Box
        width='100%'
        height='100%'
        bgcolor="#000"
        alignItems="center"
        justifyContent="center"
        display="flex"
        flexDirection="column"
        gap={2}
        {...props}
      >
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <CameraPreview sx={isMobile ? { height: '74vh' } : undefined} />
          {
            !isMobile ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                alignItems: 'center',
              }}>
                <MicSelect setCustomAudioStream={setCustomAudioStream} />
                <CameraSelect setCustomVideoStream={setCustomVideoStream} />
                <SpeakerSelect />
              </Box>
            ) : (
              <Box sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <CameraSelect setCustomVideoStream={setCustomVideoStream} />
              </Box>
            )
          }
          <JoinButton onClick={onJoin} />
        </Container>
      </Box>
    </JoiningContext.Provider>
  );
}

export default React.memo(JoiningScreen);