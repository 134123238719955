import React, { useEffect, useState }  from "react";
import { Constants, MicrophoneDeviceInfo, useMediaDevice } from "@videosdk.live/react-sdk";
import { useJoiningContext } from "../../JoiningContext";
import useMediaStream from "../../../../hooks/useMediaStream";
import { useMeetingApp } from "../../MeetingAppProvider";
import DropDownMic, { DropDownProps } from "./DropDownMic";

type MicSelect = Omit<DropDownProps, "mics" | "changeMic" | "customAudioStream" | "audioTrack" | "micOn" | "didDeviceChange" | "setDidDeviceChange"> & {
  setCustomAudioStream: React.Dispatch<React.SetStateAction<MediaStream | undefined>>;
}

const MicSelect: React.FC<MicSelect> = ({ setCustomAudioStream, ...props }) => {
  const {
    selectedMic,
    setSelectedMic,
    isMicrophonePermissionAllowed,
    setIsMicrophonePermissionAllowed
  } = useMeetingApp();
  const [mics, setMics] = useState<MicrophoneDeviceInfo[]>([]);
  const {
    checkPermissions,
    requestPermission,
    getMicrophones
  } = useMediaDevice({ onDeviceChanged });
  const { getAudioTrack } = useMediaStream();

  const { micOn, setMicOn, audioTrack, setAudioTrack } = useJoiningContext();
  const [_customAudioStream, _setCustomAudioStream] = useState<MediaStream | null>(null);

  const getDefaultMic = async () => {
    const stream = await getAudioTrack({
      micId: selectedMic ? selectedMic.id : undefined,
    });
    setCustomAudioStream(stream || undefined);
    _setCustomAudioStream(stream);
    const audioTracks = stream?.getAudioTracks();
    const audioTrack = audioTracks?.length ? audioTracks[0] : null;
    setAudioTrack(audioTrack);
  };

  async function requestAudioPermission() {
    try {
      const permission = await requestPermission(Constants.permission.VIDEO)

      /*
      // For Audio
      if (isFirefox) {
        const isAudioAllowed = permission.get("audio");
        setIsMicrophonePermissionAllowed(isAudioAllowed);
        if (isAudioAllowed) {
          setMicOn(true);
          await getDefaultMediaTracks({ mic: true, webcam: false });
        }
      }
      */

      const isAudioAllowed = permission.get(Constants.permission.AUDIO);
      setIsMicrophonePermissionAllowed(!!isAudioAllowed);
      if (isAudioAllowed) {
        setMicOn(true);
        await getDefaultMic();
      } 
    } catch (ex) {
      console.log("Error in requestPermission", ex);
    }
  }

  const checkMediaPermission = async () => {
    try {
      const checkAudioVideoPermission = await checkPermissions();
      const microphonePermissionAllowed = checkAudioVideoPermission.get(
        Constants.permission.AUDIO
      );

      setIsMicrophonePermissionAllowed(!!microphonePermissionAllowed);

      if (microphonePermissionAllowed) {
        setMicOn(true);
        getDefaultMic();
      } else {
        await requestAudioPermission();
      }
    } catch (error) {
      // For firefox, it will request audio and video simultaneously.
      await requestAudioPermission();
      console.log(error);
    }
  };

  const getAudioDevices = async () => {
    try {
      //if (permissonAvaialble.current?.isMicrophonePermissionAllowed) {
        let mics = await getMicrophones();
        console.log(mics)

        const hasMic = mics.length > 0;
        if (hasMic) {
          //startMuteListener();
        }
   
        await setSelectedMic({ id: mics[0]?.deviceId, label: mics[0]?.label });
        setMics(mics);
      //}
    } catch (err) {
      console.log("Error in getting audio devices", err);
    }
  };

  useEffect(() => {
    getAudioDevices();
  }, [isMicrophonePermissionAllowed]);

  function onDeviceChanged() {
    getAudioDevices();
  }
  useEffect(() => {
    checkMediaPermission();
  }, []);

  const changeMic = async (deviceId: string) => {
    if (micOn) {
      //const currentAudioTrack = audioTrackRef.current;
      //currentAudioTrack && currentAudioTrack.stop();
      const stream = await getAudioTrack({
        micId: deviceId,
      });
      setCustomAudioStream(stream || undefined);
      _setCustomAudioStream(stream);
      const audioTracks = stream?.getAudioTracks();
      const audioTrack = audioTracks && audioTracks.length ? audioTracks[0] : null;
      //clearInterval(audioAnalyserIntervalRef.current);
      setAudioTrack(audioTrack);
    }
  };

  return (
    <DropDownMic
      mics={mics as MediaDeviceInfo[]}
      changeMic={changeMic}
      customAudioStream={_customAudioStream as MediaStream}
      audioTrack={audioTrack as MediaStreamTrack}
      micOn={micOn}
      {...props}
    />
  )
}

export default React.memo(MicSelect);