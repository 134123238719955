import { Avatar, Box, BoxProps, IconButton, IconButtonProps, Typography } from "@mui/material";
import { useMeeting } from "@videosdk.live/react-sdk";
import React, { useEffect, useMemo, useState } from "react";
import ParticipantView, { ParticipantViewProps } from "./components/participant/ParticipantView";
import { JoinFull, Phone } from "@mui/icons-material";
import LeveButton from "./components/buttons/LeveButton";
import MicButton from "./components/buttons/MicButton";
import CameraButton from "./components/buttons/CameraButton";
import EndButton from "./components/buttons/EndButton";
import { toast } from "react-hot-toast";

const goodRedColor = '#f44336';
type StopButtonProps = IconButtonProps;
const StopButton: React.FC<StopButtonProps> = (props) => {
  return (
    <IconButton {...props}>
      <Avatar
        sx={{ width: 64, height: 64, backgroundColor: goodRedColor }}
      >
        <Phone />
      </Avatar>
    </IconButton>
  );
}

const goodGreenColor = '#4caf50';
type JoinButtonProps = IconButtonProps;
const JoinButton: React.FC<JoinButtonProps> = (props) => {
  return (
    <IconButton {...props}>
      <Avatar
        sx={{ width: 64, height: 64, backgroundColor: goodGreenColor }}
      >
        <JoinFull />
      </Avatar>
    </IconButton>
  );
}

type LocalParticipantViewProps = Omit<ParticipantViewProps, 'participantId'>;
const LocalParticipantView: React.FC<LocalParticipantViewProps> = (props) => {
  const { localParticipant } = useMeeting();
  console.log('localParticipant', localParticipant);
  return (
    <ParticipantView quality='high' participantId={localParticipant.id} {...props} />
  )
}

type SmallLocalParticipantViewProps = Omit<LocalParticipantViewProps, 'width' | 'height'>;
const SmallLocalParticipantView: React.FC<SmallLocalParticipantViewProps> = (props) => {
  return (
    <LocalParticipantView
      hideControls
      quality='low'
      width={140} height={200}
      {...props}
    />
  )
}

export function ParticipantsViewer() {
  const { participants } = useMeeting();
  console.log('participants', participants);
  const remoteParticipantId = useMemo(() => {
    const remoteParticipants = Array.from(participants.values()).filter(p => !p.local)
    return remoteParticipants.length > 0 ? remoteParticipants[0].id : null;
  }, [participants]);

  return (
    <Box width='100%' height='100%'>
      { remoteParticipantId ? (
        <ParticipantView key={remoteParticipantId} participantId={remoteParticipantId} />
      ) : (
        <Box
          width='100%'
          height='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Typography variant="h6">
            Waiting for remote participant...
          </Typography>
        </Box>
      )}
      <SmallLocalParticipantView sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        marginLeft: 2,
        marginTop: 14,
        borderRadius: 2,
        border: '1px solid #fff',
        backgroundColor: '#000',
      }} />
    </Box>
  );
}

type MeetingViewProps = BoxProps & {
  control?: boolean;
  meetingId: string;
  onMeetingLeave: () => void;
};

function MeetingView({ control, meetingId, onMeetingLeave, ...props }: MeetingViewProps) {
  const [joined, setJoined] = useState<string | null>('JOINING');
  const { join } = useMeeting();
  const mMeeting = useMeeting({
    onMeetingJoined: () => {
      setJoined("JOINED");
    },
    onMeetingLeft: () => {
      onMeetingLeave();
    },
    onParticipantJoined: (participant) => {
      console.log('participant joined', participant);
      toast.success(`Participant ${participant.displayName} joined`);
      participant.setQuality("high");
    },
    onParticipantLeft: (participant) => {
      console.log('participant left', participant);
      toast.success(`Participant ${participant.displayName} left`);
    },
  });

  const joinMeeting = () => {
    setJoined("JOINING");
    join();
  };
  useEffect(() => {
    joinMeeting();
  }, []);
  
  
  return (
    <Box
      width='100%'
      height='100%'
      bgcolor="#000"
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="column"
      gap={2}
      {...props}
    >
      {joined == "JOINED" ? (
        <Box width='100%' height='100%'>
          <ParticipantsViewer />
          <Box sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 2,
          }}>
            <CameraButton />
            <MicButton />
            <LeveButton />
            { control && <EndButton />}
          </Box>
        </Box>
      ) : joined == "JOINING" ? (
        <Typography variant="h6">Joining the room...</Typography>
      ) : (
        <StopButton onClick={() => onMeetingLeave()} sx={{ paddingTop: 4 }} />
      )}
    </Box>
  );
}

export default React.memo(MeetingView);