import React, { useContext }  from "react";

type JoiningContextType = {
  micOn: boolean;
  setMicOn: (on: boolean) => void;
  audioTrack: MediaStreamTrack | null;
  setAudioTrack: (track: MediaStreamTrack | null) => void;

  webcamOn: boolean;
  setWebcamOn: (on: boolean) => void;
  videoTrack: MediaStreamTrack | null;
  setVideoTrack: (track: MediaStreamTrack | null) => void;

  speakerOn: boolean;
  setSpeakerOn: (on: boolean) => void;
};
const JoiningContext = React.createContext<JoiningContextType>({
  micOn: false,
  setMicOn: () => {},
  audioTrack: null,
  setAudioTrack: () => {},

  webcamOn: false,
  setWebcamOn: () => {},
  videoTrack: null,
  setVideoTrack: () => {},

  speakerOn: false,
  setSpeakerOn: () => {},
});

export const useJoiningContext = () => {
  return useContext(JoiningContext);
}

export default JoiningContext;