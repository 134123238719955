import React from 'react';
import { Typography } from '@mui/material';
import { useModel } from '../../providers/ModelProvider';

const ModelStatus: React.FC = () => {
  const { isOnline } = useModel();
  return (
    <Typography variant='h6' color={isOnline ? 'green' : 'red'}>
      {isOnline ? 'Online' : 'Offline'}
    </Typography>
  );
};

export default ModelStatus;
