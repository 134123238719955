import React, { useContext, createContext, useState } from "react";

type Hardware = {
  id: string | null;
  label: string | null;
  deviceId?: string;
};

export type Speaker = {
  id: string | null;
  deviceId: string;
  label: string | null;
  kind: string;
}

type MeetingAppContextType = {
  // states
  selectedMic: Hardware | null;
  selectedWebcam: Hardware | null;
  selectedSpeaker: Speaker | null;
  isCameraPermissionAllowed: boolean | null;
  isMicrophonePermissionAllowed: boolean | null;

  // setters
  setSelectedMic: React.Dispatch<React.SetStateAction<Hardware | null>>;
  setSelectedWebcam: React.Dispatch<React.SetStateAction<Hardware | null>>;
  setSelectedSpeaker: React.Dispatch<React.SetStateAction<Speaker | null>>;
  setIsCameraPermissionAllowed: React.Dispatch<React.SetStateAction<boolean | null>>;
  setIsMicrophonePermissionAllowed: React.Dispatch<React.SetStateAction<boolean | null>>;
}

export const MeetingAppContext = createContext<MeetingAppContextType>({
  // states
  selectedMic: null,
  selectedWebcam: null,
  selectedSpeaker: null,
  isCameraPermissionAllowed: null,
  isMicrophonePermissionAllowed: null,

  // setters
  setSelectedMic: () => {},
  setSelectedWebcam: () => {},
  setSelectedSpeaker: () => {},
  setIsCameraPermissionAllowed: () => {},
  setIsMicrophonePermissionAllowed: () => {},
});

export const useMeetingApp = () => useContext(MeetingAppContext);

type MeetingAppProviderProps = {
  children: React.ReactNode;
};

export const MeetingAppProvider = ({ children }: MeetingAppProviderProps) => {
  const [selectedMic, setSelectedMic] = useState<Hardware | null>(null);
  const [selectedWebcam, setSelectedWebcam] = useState<Hardware | null>(null);
  const [selectedSpeaker, setSelectedSpeaker] = useState<Speaker | null>(null);
  const [isCameraPermissionAllowed, setIsCameraPermissionAllowed] = useState<boolean | null>(null);
  const [isMicrophonePermissionAllowed, setIsMicrophonePermissionAllowed] = useState<boolean | null>(null);

  return (
    <MeetingAppContext.Provider
      value={{
        // states
        selectedMic,
        selectedWebcam,
        selectedSpeaker,
        isCameraPermissionAllowed,
        isMicrophonePermissionAllowed,

        // setters
        setSelectedMic,
        setSelectedWebcam,
        setSelectedSpeaker,
        setIsCameraPermissionAllowed,
        setIsMicrophonePermissionAllowed,
      }}
    >
      {children}
    </MeetingAppContext.Provider>
  );
};