import React, { useState, MouseEvent } from 'react';
import {
  Button,
  Typography,
  ButtonProps,
  styled,
} from '@mui/material';

const StyledButton = styled(Button)<{ isopen?: boolean; isdisabled?: boolean }>(({ theme, isopen, isdisabled }) => ({
  width: '11rem',
  textTransform: 'none',
  backgroundColor: isopen ? 'black' : 'grey.250',
  color: isopen ? 'white' : 'grey.250',
  '&:hover': {
    backgroundColor: 'black',
    color: 'white',
  },
  opacity: isdisabled ? 0.5 : 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export interface DeviceButtonProps extends ButtonProps {
  isPermissionAllowed?: boolean;
  open?: boolean;
}

const DeviceButton: React.FC<DeviceButtonProps> = ({ children, open, isPermissionAllowed, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <StyledButton
      disabled={!isPermissionAllowed}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isopen={open}
      isdisabled={!isPermissionAllowed}
      {...props}
    >
      <Typography
        variant="body1"
        noWrap
        sx={{ flexGrow: 1, ml: 1 }}
      >
        {children}
      </Typography>
    </StyledButton>
  );
}

export default React.memo(DeviceButton);