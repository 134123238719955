import cfg from "../config";
import { getAccesToken } from "./auth";

type BallnaceResponse = {
  amount: number;
}

export const fetchBallance = async (): Promise<number> => {
  const url = `${cfg.apiUrl}/api/v1/account/ballance`;
  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccesToken()}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to get ballance');
  }
  
  const data: BallnaceResponse = await response.json();
  return data.amount;
}

type PayProps = {
  amount: number;
}

export const buy = async (body: PayProps): Promise<number> => {
  const url = `${cfg.apiUrl}/api/v1/account/buy`;
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccesToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  });

  if (!response.ok) {
    const text = await response.text();
    throw new Error(`response status: ${response.status}, text: ${text}`);
  }
  
  const data: BallnaceResponse = await response.json();
  return data.amount;
}

export type PaymentData = {
  tokenBoxId: string;
  senderAddress: string;
  channel: 'ton' | 'fake';
  txHash: string;
  modelId?: string;
}

export const payment = async (body: PaymentData) => {
  const url = `${cfg.apiUrl}/api/v1/account/payment`;
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccesToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  });

  if (!response.ok) {
    const text = await response.text();
    throw new Error(`response status: ${response.status}, text: ${text}`);
  }

  const data: BallnaceResponse = await response.json();
  return {
    ballance: data.amount
  }
}

export type SubscribeProps = {
  modelId: string;
  levelId: string;
}

export const subscribe = async (data: SubscribeProps) => {
  const url = `${cfg.apiUrl}/api/v1/account/subscribe`;
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccesToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  });

  if (!response.ok) {
    const text = await response.text();
    throw new Error(`response status: ${response.status}, text: ${text}`);
  }
}

export const setAutoRenew = async (modeleId: string, autoRenew: boolean) => {
  const url = `${cfg.apiUrl}/api/v1/account/subscribe/auto-renew`;
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccesToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      modelId: modeleId,
      autoRenew
    })
  });

  if (!response.ok) {
    const text = await response.text();
    throw new Error(`response status: ${response.status}, text: ${text}`);
  }
}

export const upgrade = async (data: SubscribeProps) => {
  const url = `${cfg.apiUrl}/api/v1/account/upgrade`;
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccesToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  });

  if (!response.ok) {
    const text = await response.text();
    throw new Error(`response status: ${response.status}, text: ${text}`);
  }
}

export type UnSubscribeProps = {
  modelId: string;
}

export const unsubscribe = async (data: UnSubscribeProps) => {
  const url = `${cfg.apiUrl}/api/v1/account/unsubscribe`;
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccesToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  });

  if (!response.ok) {
    const text = await response.text();
    throw new Error(`response status: ${response.status}, text: ${text}`);
  }
}