import React, { useState, useEffect, MouseEvent } from 'react';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  Box,
  LinearProgress,
  ButtonProps,
  Paper,
  MenuList,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { Speaker, useMeetingApp } from '../../MeetingAppProvider';
import { PlaybackDeviceInfo } from '@videosdk.live/react-sdk/dist/types/deviceInfo';
import DeviceButton from '../../shared/DeviceButton';

export interface DropDownSpeakerProps extends ButtonProps {
  speakers: PlaybackDeviceInfo[];
}

const DropDownSpeaker: React.FC<DropDownSpeakerProps> = ({ speakers }) => {
  const {
    setSelectedSpeaker,
    selectedSpeaker,
    isMicrophonePermissionAllowed,
  } = useMeetingApp();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [audioProgress, setAudioProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const testSpeakers = () => {
    if (selectedSpeaker?.deviceId) {
      const newAudio = new Audio('/sounds/test_sound.mp3');
      newAudio.setSinkId(selectedSpeaker.deviceId)
        .then(() => {
          newAudio.play();
          setIsPlaying(true);
          newAudio.addEventListener('timeupdate', () => {
            if (newAudio.duration) {
              const progress = (newAudio.currentTime / newAudio.duration) * 100;
              setAudioProgress(progress);
            }
          });
          newAudio.addEventListener('ended', () => {
            setAudioProgress(0);
            setIsPlaying(false);
          });
          setAudio(newAudio);
        })
        .catch(error => {
          console.error('Cannot play sound on selected speaker', error);
        });
    } else {
      console.error('No deviceId found');
    }
  };

  useEffect(() => {
    return () => {
      if (audio) {
        audio.pause();
        audio.removeAttribute('src');
        setAudio(null);
      }
    };
  }, [audio]);

  return (
    <>
      <DeviceButton
        onClick={handleMenuOpen}
        disabled={!isMicrophonePermissionAllowed}
        isPermissionAllowed={!!isMicrophonePermissionAllowed}
        startIcon={<VolumeUpIcon />}
        endIcon={<ExpandMoreIcon />}
        open={open}
      >
        {isMicrophonePermissionAllowed ? selectedSpeaker?.label : 'Required permission'}
      </DeviceButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 3,
          sx: { mt: 1.5, width: '18rem', p: 1, backgroundColor: '#4B5563', borderRadius: 2 },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper>
          <MenuList>
            {speakers
              .filter(speaker => speaker.kind === 'audiooutput')
              .map((speaker, index) => (
                <MenuItem
                  key={`speaker_${index}`}
                  selected={selectedSpeaker?.label === speaker.label}
                  onClick={() => {
                    setSelectedSpeaker({
                      id: speaker.deviceId,
                      label: speaker.label,
                      deviceId: speaker.deviceId,
                      kind: speaker.kind,
                    });
                    handleMenuClose();
                  }}
                  sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
                >
                  {selectedSpeaker?.label === speaker.label && (
                    <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                      <CheckIcon fontSize="small" />
                    </ListItemIcon>
                  )}
                  <Typography variant="body2">
                    {speaker.label || `Speaker ${index + 1}`}
                  </Typography>
                </MenuItem>
              ))}
            {speakers.length > 0 && (
              <>
                <Box sx={{ borderTop: '1px solid', borderColor: '#D1D5DB', my: 1 }} />
                <MenuItem
                  onClick={testSpeakers}
                  sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
                >
                  <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                    <VolumeOffIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="body2">Check speakers</Typography>
                </MenuItem>
                {isPlaying && (
                  <Box sx={{ width: '100%', mt: 1 }}>
                    <LinearProgress variant="determinate" value={audioProgress} />
                  </Box>
                )}
              </>
            )}
          </MenuList>
        </Paper>
      </Menu>
    </>
  );
};

export default React.memo(DropDownSpeaker);
