import React from 'react';
import { useModel } from '../../providers/ModelProvider';
import { useMyModelSub } from '../../../hooks/useSubs';
import toast from 'react-hot-toast';
import { useAccount } from '../../providers/AccountProvider';
import {
  Switch,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';


export type AutoRenewSwitchProps = Omit<FormControlLabelProps, 'control' | 'label'>;
const AutoRenewSwitch: React.FC<AutoRenewSwitchProps> = ({ disabled, ...props }) => {
  const { modelId } = useModel();
  const { sub, mutate } = useMyModelSub(modelId);
  const { setAutoRenew } = useAccount();
  const [waiting, setWaiting] = React.useState(false);
  const handleAutoRenewChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!modelId) return;
    setWaiting(true);
    try {
      await toast.promise(
        setAutoRenew(modelId, e.target.checked),
        {
          loading: 'Updating subscription...',
          success: 'Subscription updated!',
          error: 'Failed to update subscription.',
        }
      );
      mutate();
    } catch (e) {
      console.error(e);
    } finally {
      setWaiting(false);
    }
  };

  return (
    <FormControlLabel
      control={
        <Switch
          disabled={!sub || disabled}
          checked={sub?.autoRenew || false}
          onChange={handleAutoRenewChange}
          name="autoRenew"
        />
      }
      label="Auto renew subscription"
      {...props}
    />
  );
};

export default React.memo(AutoRenewSwitch);