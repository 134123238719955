import React from "react";
import { Box, BoxProps } from "@mui/material";
import VideoQualityButton from "../config/VideoQualityButton";
import ParticipantMicInfo from "./ParticipantMicInfo";
import ParticipantAudio from "./ParticipantAudio";
import ParticipantVideo from "./ParticipantVideo";
import { useParticipant } from "@videosdk.live/react-sdk";


export type ParticipantViewProps = BoxProps & {
  hideControls?: boolean;
  participantId: string;
  quality?: 'high' | 'low';
};

function ParticipantView({ hideControls, participantId, quality, ...props }: ParticipantViewProps) {
  const { isLocal } = useParticipant(participantId);
  return (
    <Box width='100%' height='100%' {...props}>
      <Box sx={{
        position: "absolute", top: 0, left: 0, zIndex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center", 
        width: "100%",
        paddingLeft: 2,
        paddingRight: 2,
        gap: 1,
      }}>
        <ParticipantMicInfo participantId={participantId}>
          {participantId}
        </ParticipantMicInfo>
        <VideoQualityButton participantId={participantId} sx={{ display: hideControls ? "none" : "flex", }} />
      </Box>
      <ParticipantVideo participantId={participantId} quality={quality} />
      {
        !isLocal && (
          <ParticipantAudio participantId={participantId} />
        )
      }
    </Box>
  );
}

export default React.memo(ParticipantView);