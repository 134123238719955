import React, { useCallback } from 'react';
import MobilePage from '../../../../components/layout/MobilePage';
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { ModelProvider } from "../../../../components/providers/ModelProvider";
import MeetingWidget, { MeetingWidgetProps } from '../../../../components/meeting/MeetingWidget';
import { useAuth } from '../../../../components/providers/AuthProvider';

const UserMeetinWidget: React.FC<MeetingWidgetProps> = (props) => {
  const { user } = useAuth()
  return <MeetingWidget {...props} participantName={user?.name} />
}

const MeetingPage: React.FC = () => {
  const { alias, roomId } = useParams<{ roomId: string, alias: string }>();
  console.log('roomId', roomId, 'alias', alias);

  const navigate = useNavigate();
  const onMeetingLeave = useCallback(() => {
    navigate(`/bot/${alias}`);
    window.location.reload();
  }, [alias]);

  if(!alias) {
    return <Typography variant="h6">Invalid Alias</Typography>;
  }
  if(!roomId) {
    return <Typography variant="h6">Invalid Room ID</Typography>;
  }

  return (
    <ModelProvider alias={alias}>
      <MobilePage title='Meeting' hideBottomNav fullSize>
        <UserMeetinWidget roomId={roomId} onMeetingLeave={onMeetingLeave} />
      </MobilePage>
    </ModelProvider>
  );
};

export default MeetingPage;