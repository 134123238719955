import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Stack
} from '@mui/material';
import { useAccount } from '../providers/AccountProvider';
import { toast } from "react-hot-toast";
import { useTonConnect } from "../../hooks/useTonConnect";
import TokenBoxSelect from '../containers/TokenBoxSelect';
import { TokenBox } from '../../types/token-box';
import { useTokenBoxes } from '../../hooks/useTokenBoxes';
import { v4 as uuidv4 } from 'uuid';
import { useModel } from '../providers/ModelProvider';
import { TonConnectButton } from '@tonconnect/ui-react';
// https://github.com/ton-community/twa-template/blob/main/src/components/TransferTon.tsx
// https://docs.ton.org/develop/dapps/ton-connect/react
// https://github.com/allen-hsu/demo-waiting-tx/blob/master/src/components/TxForm/TxForm.tsx
// https://medium.com/@amyabafor013/step-by-step-how-to-integrate-ton-payments-in-your-telegram-mini-app-bc23d348344e
interface PaymentDialogProps {
  open: boolean;
  onPayment: (amount: string) => void;
  onClose: () => void;
}


const PaymentDialog: React.FC<PaymentDialogProps> = ({ open, onClose, onPayment }) => {
  const { wallet, sender, connected } = useTonConnect();
  const { modelId } = useModel();

  const { tokenBoxes } = useTokenBoxes();
  const [tokenBox, setTokenBox] = useState<TokenBox | undefined>(tokenBoxes.find((box) => box.default));

  useEffect(() => {
    if(tokenBoxes.length === 0) return;
    setTokenBox(tokenBoxes.find((box) => box.default) || tokenBoxes[0]);
  }, [open, tokenBoxes]);

  const { payment } = useAccount();

  const [waiting, setWaiting] = useState(false);
  const handlePay = async () => {
    if (!tokenBox) return;
    try {
      setWaiting(true);
      const txHash = uuidv4().replace(/-/g, '');
      await toast.promise(payment({
        senderAddress: 'fake',
        tokenBoxId: tokenBox.id,
        channel: 'fake',
        txHash,
        modelId,
      }), {
        loading: 'Paying...',
        success: 'Paid!',
        error: 'Failed to pay',
      });
      const tonAmount = tokenBox.price;
      onPayment(tonAmount);
    } catch (error: any) {
      toast.error('Failed to pay ' + error.message);
      console.error(error);
    } finally {
      setWaiting(false);
    }
  }

  const handleTransfer = async () => {
    if (!sender) return;
    if(!tokenBox) return;
    if(!wallet) return;

    try {
      setWaiting(true);
      const tonAmount = tokenBox.price;
      const send = async () => {
        // Create a unique transaction hash
        const transactionId = uuidv4().replace(/-/g, '');

        // Send the payment
        await sender.send({ value: tonAmount, transactionId });

        // Save the payment to the database and wait for the payment to be confirmed
        await payment({
          senderAddress: wallet,
          tokenBoxId: tokenBox.id,
          channel: 'ton',
          txHash: transactionId,
          modelId,
        });
      }
      await toast.promise(send(), {
        loading: 'Paying...',
        success: 'Paid!',
        error: 'Failed to pay',
      });

      onPayment(tonAmount);
    } catch (error: any) {
      toast.error('Failed to transfer ');
      console.error(error);
    } finally {
      setWaiting(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>Payment</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {/*
        <Typography>Transfer TON</Typography>
        <TextField
          label="Amount"
          type="number"
          value={tonAmount}
          fullWidth
          onChange={(e) => setTonAmount(e.target.value)}
        />
        <TextField
          label="To"
          value={tonRecipient}
          fullWidth
          disabled
          onChange={(e) => setTonRecipient(e.target.value)}
        />
        */}
        <TokenBoxSelect
          value={tokenBox}
          onSelected={(box) => setTokenBox(box)}
        />
        <Stack direction='column' gap={2}>
          <Button onClick={onClose} color="error" variant='contained' fullWidth size='large' sx={{ mt: 4 }} disabled={waiting}>
            Cancel
          </Button>
          {/* 
          <Button
            onClick={handlePay}
            color="primary" variant='contained' fullWidth size='large'
            disabled={waiting || !tokenBox}
          >
            Fake Transfer
          </Button>
          */}
          <Button
            color="primary" variant='contained' fullWidth size='large'
            disabled={waiting || !connected || !wallet || !tokenBox}
            onClick={handleTransfer}
          >
            {connected ? 'Buy' : 'Buy (Please connect wallet)'}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDialog;