import { Box, BoxProps, styled } from "@mui/material";
import React, { useEffect, useRef }  from "react";
import { useJoiningContext } from "../JoiningContext";
import { toast } from "react-hot-toast";

const VideoPlayer = styled('video')(({ theme }) => ({
  backgroundColor: "#1c1c1c",
  borderRadius: theme.spacing(2),
  objectFit: 'contain',
  width: '100%',
  height: '100%',
}));

type CameraPreviewProps = BoxProps;
const CameraPreview: React.FC<CameraPreviewProps> = ({ sx, ...props }) => {
  const { webcamOn, videoTrack } = useJoiningContext();
  const videoPlayerRef = useRef<HTMLVideoElement>(null);
  const videoTrackRef = useRef<MediaStreamTrack | null>(null);
  useEffect(() => {
    if(!videoPlayerRef.current) return;
    if (webcamOn) {

      // Close the existing video track if there's a new one
      if (videoTrackRef.current && videoTrackRef.current !== videoTrack) {
        videoTrackRef.current.stop(); // Stop the existing video track
      }

      videoTrackRef.current = videoTrack;

      var isPlaying =
        videoPlayerRef.current.currentTime > 0 &&
        !videoPlayerRef.current.paused &&
        !videoPlayerRef.current.ended &&
        videoPlayerRef.current.readyState >
        videoPlayerRef.current.HAVE_CURRENT_DATA;

      if (videoTrack) {
        const videoSrcObject = new MediaStream([videoTrack]);

        if (videoPlayerRef.current) {
          videoPlayerRef.current.srcObject = videoSrcObject;
          /*
          if (videoPlayerRef.current.pause && !isPlaying) {
            videoPlayerRef.current
              .play()
              .catch((error) => {
                console.error("Failed to play video", error)
                toast.error("Failed to play video");
              });
          }
          */
        }
      } else {
        if (videoPlayerRef.current) {
          videoPlayerRef.current.srcObject = null;
        }
      }
    }
  }, [webcamOn, videoTrack]);

  return (
    <Box {...props} sx={{ width: '100%', height: '100%', ...sx }}>
      <VideoPlayer
        autoPlay
        playsInline
        muted
        ref={videoPlayerRef}
        controls={false}
      />
    </Box>
  )
}

export default React.memo(CameraPreview);