import React, { useEffect, useState }  from "react";
import DropDownCam, { DropDownCamProps } from "./DropDownCam";
import { CameraDeviceInfo, Constants, useMediaDevice } from "@videosdk.live/react-sdk";
import { useMeetingApp } from "../../MeetingAppProvider";
import useMediaStream from "../../../../hooks/useMediaStream";
import { useJoiningContext } from "../../JoiningContext";

type CameraSelect = Omit<DropDownCamProps, 'webcams' | 'changeWebcam'> & {
  setCustomVideoStream: React.Dispatch<React.SetStateAction<MediaStream | undefined>>;
}

const CameraSelect: React.FC<CameraSelect> = ({ setCustomVideoStream, ...props }) => {
  const {
    selectedWebcam,
    setSelectedWebcam,
    setIsCameraPermissionAllowed,
    isCameraPermissionAllowed,
  } = useMeetingApp();
  const [webcams, setWebcams] = useState<CameraDeviceInfo[]>([]);
  const {
    checkPermissions,
    requestPermission,
    getCameras
  } = useMediaDevice({ onDeviceChanged });
  const { getVideoTrack } = useMediaStream();
  const { webcamOn, videoTrack, setVideoTrack } = useJoiningContext();

  const getDefaultWebcam = async () => {
    const stream = await getVideoTrack({
      webcamId: selectedWebcam ? selectedWebcam.id : undefined,
    });
    // setCustomVideoStream(stream); UP
    const videoTracks = stream?.getVideoTracks();
    const videoTrack = videoTracks?.length ? videoTracks[0] : null;
    setVideoTrack(videoTrack);
  };

  async function requestVideoPermission() {
    try {
      const permission = await requestPermission(Constants.permission.VIDEO)

      // For Video
      /*
      if (isFirefox) {
        const isVideoAllowed = permission.get("video");
        setIsCameraPermissionAllowed(isVideoAllowed);
        if (isVideoAllowed) {
          setWebcamOn(true);
          await getDefaultMediaTracks({ mic: false, webcam: true });
        }
      }
      */

      const isVideoAllowed = permission.get(Constants.permission.VIDEO);
      setIsCameraPermissionAllowed(!!isVideoAllowed);
      if (isVideoAllowed) {
        //setWebcamOn(true);
        await getDefaultWebcam();
      }
    } catch (ex) {
      console.log("Error in requestPermission", ex);
    }
  }

  const checkMediaPermission = async () => {
    try {
      const checkAudioVideoPermission = await checkPermissions();
      const cameraPermissionAllowed = checkAudioVideoPermission.get(
        Constants.permission.VIDEO
      );
      
      setIsCameraPermissionAllowed(!!cameraPermissionAllowed);
      if (cameraPermissionAllowed) {
        //setWebcamOn(true);
        getDefaultWebcam();
      } else {
        await requestVideoPermission();
      }
    } catch (error) {
      // For firefox, it will request audio and video simultaneously.
      await requestVideoPermission();
      console.log(error);
    }
  };

  const getCameraDevices = async () => {
    try {
      //if (permissonAvaialble.current?.isCameraPermissionAllowed) {
        let webcams = await getCameras();
        setSelectedWebcam({
          id: webcams[0]?.deviceId,
          label: webcams[0]?.label,
        });
  
        setWebcams(webcams);
      //}
    } catch (err) {
      console.log("Error in getting camera devices", err);
    }
  };

  function onDeviceChanged() {
    getCameraDevices();
  }

  useEffect(() => {
    getCameraDevices();
  }, [isCameraPermissionAllowed]);

  useEffect(() => {
    checkMediaPermission();
  }, []);

  const changeWebcam = async (deviceId: string) => {
    if (webcamOn) {
      /*
      const currentvideoTrack = videoTrackRef.current;
      if (currentvideoTrack) {
        currentvideoTrack.stop();
      }
        */

      const stream = await getVideoTrack({
        webcamId: deviceId,
      });
      setCustomVideoStream(stream || undefined);
      const videoTracks = stream?.getVideoTracks();
      const videoTrack = videoTracks?.length ? videoTracks[0] : null;
      setVideoTrack(videoTrack);
    }
  };

  return (
    <DropDownCam
      webcams={webcams}
      changeWebcam={changeWebcam}
      {...props}
    />
  );
}

export default React.memo(CameraSelect);