import React, { useCallback, useState } from 'react';
import GoldButton, { GoldButtonProps } from '../../dumps/GoldButton';
import { useModel } from '../../providers/ModelProvider';
import SubscribeDialog from '../../dialogs/SubscribeDialog';
import UpgradeDialog from '../../dialogs/SubscribeDialog';
import { useMyModelSub } from '../../../hooks/useSubs';
import {
  darken,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  GoldAccordion,
  GoldAccordionSummary,
  GoldAccordionDetails
} from '../../dumps/GoldAccordion';
import AutoRenewSwitch from './AutoRenewSwitch';
import NextChargeDate from './NextChargeDate';

export type SubscribeButtonProps = Omit<GoldButtonProps, 'onClick'>;

function level2Title(level?: string) {
  switch (level) {
    case '1':
      return `Bronze Level 🥉`;
    case '2':
      return `Silver Level 🥈`;
    case '3':
      return 'Gold Level 🥇';
    default:
      return undefined;
  }
}

function level2color(level: string) {
  switch (level) {
    case '1': // dark bronze 
      return darken('#8C603A', 0.2);
    case '2': // dark silver
      return darken('#A0A0A0', 0.2);
    case '3': // dark gold
      return darken('#B8860B', 0.2);
    default:
      return undefined;
  }
}


type UpgradeButtonProps = GoldButtonProps & {
  onChanged?: () => void;
}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({ onChanged, ...props }) => {
  const [open, setOpen] = useState(false);
  const { modelId } = useModel();
  const handleUpgradeClick = () => {
    setOpen(true);
  };

  return (
    <>
      <GoldButton
        variant="contained"
        fullWidth
        size="large"
        {...props}
        onClick={handleUpgradeClick}
        disabled={!modelId}
      >
        Upgrade
      </GoldButton>
      {open && modelId && (
        <UpgradeDialog
          open={open}
          modelId={modelId}
          onClose={() => setOpen(false)}
          onSubscribed={() => {
            setOpen(false);
            onChanged && onChanged();
          }}
        />
      )}
    </>
  );
}

const SubscribeButton: React.FC<SubscribeButtonProps> = ({ disabled, ...props }) => {
  const [open, setOpen] = useState(false);
  const { modelId } = useModel();
  const { sub, mutate } = useMyModelSub(modelId);
  const [expanded, setExpanded] = useState(false);

  const handleSubscribeClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleAccordionChange = useCallback((event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded);
  }, []);

  const useHasSubscribtion = sub && sub.levelId;

  return (
    <>
      {useHasSubscribtion ? (
        <GoldAccordion
          expanded={expanded}
          onChange={handleAccordionChange}
        >
          <GoldAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            color={sub.levelId ? level2color(sub.levelId) : undefined}
          >
            {level2Title(sub.levelId)}
          </GoldAccordionSummary>
          <GoldAccordionDetails>
            <Box p={2}>
              <NextChargeDate />
              <AutoRenewSwitch />
            </Box>
            {
              sub.levelId !== '3' && (
                <UpgradeButton onChanged={mutate} />
              )
            }
          </GoldAccordionDetails>
        </GoldAccordion>
      ) : (
        <GoldButton
          variant="contained"
          fullWidth
          size="large"
          {...props}
          onClick={handleSubscribeClick}
          disabled={disabled || !modelId}
        >
          Subscribe
        </GoldButton>
      )}
      {open && modelId && (
        <SubscribeDialog
          open={open}
          modelId={modelId}
          onClose={() => setOpen(false)}
          onSubscribed={() => {
            setOpen(false);
            mutate();
          }}
        />
      )}
    </>
  );
};

export default React.memo(SubscribeButton);
