import React, { useCallback, useState } from "react";
import MicNoneIcon from '@mui/icons-material/MicNone';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import CheckIcon from '@mui/icons-material/Check';
import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import { useMeetingApp } from '../../MeetingAppProvider';
import { Box, MenuItem, MenuList, Paper, Popover, Typography } from '@mui/material';
import DeviceButton from '../../shared/DeviceButton';
import VolumeMeter from "./VolumeMeter";
import MicTest from "./MicTest";

export type DropDownProps = {
  mics: MediaDeviceInfo[];
  changeMic: (deviceId: string) => void;
  customAudioStream: MediaStream;
  audioTrack: MediaStreamTrack;
  micOn: boolean;
}

export default function DropDownMic({
  mics,
  changeMic,
  customAudioStream,
  audioTrack,
  micOn,
}: DropDownProps) {
  const {
    setSelectedMic,
    selectedMic,
    isMicrophonePermissionAllowed
  } = useMeetingApp();

  const [didDeviceChange, setDidDeviceChange] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDeviceChange = useCallback((event: any) => {
    setDidDeviceChange(Math.random());
    setAnchorEl(event.currentTarget);
  }, []);

  const handleDeviceSelect = (device: MediaDeviceInfo) => {
    setSelectedMic({
      ...selectedMic,
      label: device?.label,
      id: device?.deviceId,
    });
    changeMic(device.deviceId);
    setDidDeviceChange(Math.random());
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const inputs = mics.filter((item) => item.kind === "audioinput");

  return (
    <>
      <DeviceButton
        disabled={!isMicrophonePermissionAllowed}
        isPermissionAllowed={!!isMicrophonePermissionAllowed}
        open={open}
        onClick={handleDeviceChange}
        startIcon={<MicNoneIcon />}
        endIcon={<ChevronDownIcon />}
      >
        {isMicrophonePermissionAllowed ? selectedMic?.label : "Permission Needed"}
      </DeviceButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom',  horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          style: {
            marginTop: 12,
            width: 288,
            padding: 8,
            borderRadius: 8,
          },
        }}
      >
        <Paper>
          <MenuList>
            {inputs.map((item, index) => (
              <MenuItem
                key={`mics_${index}`}
                onClick={() => handleDeviceSelect(item)}
              >
                {selectedMic?.label === item.label && (
                  <CheckIcon fontSize="small" />
                )}
                <Typography variant="body2" style={{ marginLeft: 16 }}>
                  {item?.label || `Mic ${index + 1}`}
                </Typography>
              </MenuItem>
            ))}
          </MenuList>
          <hr style={{ border: '1px solid #E0E0E0', margin: '8px 0' }} />
          {micOn ? (
            <Box sx={{ padding: 1, display: 'flex', alignItems: 'center' }}>
              <MicIcon  />
              <Box sx={{ width: 144, marginLeft: 2, backgroundColor: '#E0E0E0', borderRadius: 8, height: 4 }}>
                <VolumeMeter audioTrack={audioTrack} />
              </Box>
              <MicTest 
                disabled={!open}
                customAudioStream={customAudioStream}
                didDeviceChange={didDeviceChange}
              />
            </Box>
          ) : (
            <Box sx={{ padding: 1, display: 'flex', alignItems: 'center', color: '#747B84' }}>
              <MicOffIcon />
              <Typography variant="body2" sx={{ marginLeft: 1 }}>
                Unmute to test your mic
              </Typography>
            </Box>
          )}
        </Paper>
      </Popover>
    </>
  )
}