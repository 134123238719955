import { PlaybackDeviceInfo } from "@videosdk.live/react-sdk/dist/types/deviceInfo";
import React, { useEffect, useState }  from "react";
import DropDownSpeaker, { DropDownSpeakerProps } from "./DropDownSpeaker";
import { useMeetingApp } from "../../MeetingAppProvider";
import { useMediaDevice } from "@videosdk.live/react-sdk";

type SpeakerSelectProps = Omit<DropDownSpeakerProps, 'speakers'>;
const SpeakerSelect: React.FC<SpeakerSelectProps> = (props) => {
  const [speakers, setSpeakers] = useState<PlaybackDeviceInfo[]>([]);
  const { setSelectedSpeaker } = useMeetingApp();
  
  const {
    getPlaybackDevices,
  } = useMediaDevice({ onDeviceChanged });

  const getAudioDevices = async () => {
    try {
      //if (permissonAvaialble.current?.isMicrophonePermissionAllowed) {
        let speakers = await getPlaybackDevices();
   
        if(speakers.length > 0) {
          const firstSpeaker = speakers[0];
          await setSelectedSpeaker({
            id: firstSpeaker.deviceId,
            label: firstSpeaker.label,
            deviceId: firstSpeaker.deviceId,
            kind: firstSpeaker.kind,
          });
        }
        setSpeakers(speakers);
      //}
    } catch (err) {
      console.log("Error in getting audio devices", err);
    }
  };


  useEffect(() => {
    getAudioDevices()
  }, [])

  function onDeviceChanged() {
    getAudioDevices();
  }

  return (
    <DropDownSpeaker
      speakers={speakers}
      {...props}
    />
  )
}

export default React.memo(SpeakerSelect);