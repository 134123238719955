import { Box } from "@mui/material";
import { useParticipant } from "@videosdk.live/react-sdk";
import React, { useEffect, useMemo } from "react";
import ReactPlayer from "react-player";
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

export type ParticipantVideoProps = {
  participantId: string;
  quality?: 'high' | 'low';
};

function ParticipantVideo({ participantId, quality }: ParticipantVideoProps) {
  const { webcamStream, webcamOn } = useParticipant(participantId);
  const { setQuality } = useParticipant(participantId);

  // Change video quality
  useEffect(() => {
    if (quality) { setQuality(quality) }
  }, [quality]);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  return webcamOn ? (
    <ReactPlayer
      playsinline // very very imp prop
      pip={false}
      light={false}
      controls={false}
      muted={true}
      playing={true}
      url={videoStream}
      width="100%"
      height="100%"
      onError={(err) => {
        console.log(err, "participant video error");
      }}
    />
  ) : (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VideocamOffIcon sx={{ fontSize: 100, color: "rgba(255,255,255,0.5)" }} />
    </Box>
  );
}

export default React.memo(ParticipantVideo);