import React from "react";
import cfg from "../../config";

type IsPaymentsProps = {
  children: React.ReactNode;
};
export const IsPayments: React.FC<IsPaymentsProps> = ({ children }) => {
  return cfg.payments ? (
    <>{children}</>
  ) : null;
}