import React, { useEffect, useRef, useState } from "react";
import { styled } from '@mui/material';
import { step } from "../../../../lib/utils";

function createAnalyser(audioContext: AudioContext) {
  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 512;
  analyser.minDecibels = -127;
  analyser.maxDecibels = 0;
  analyser.smoothingTimeConstant = 0.4;
  return analyser;
};

const VolumeBar = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.primary.main,
  opacity: 0.8,
}));

function VolumeMeter({ audioTrack }: { audioTrack: MediaStreamTrack }) {
  // Audio Analyser
  const audioTrackRef = useRef<MediaStreamTrack>();
  const [volume, setVolume] = useState<number>(0);
  const audioAnalyserIntervalRef = useRef<any>();
  const analyseAudio = (audioTrack: MediaStreamTrack) => {
    const audioContext = new AudioContext();
    const audioStream = new MediaStream([audioTrack]);
    const audioSource = audioContext.createMediaStreamSource(audioStream);
    const analyser = createAnalyser(audioContext);

    audioSource.connect(analyser);

    const volumes = new Uint8Array(analyser.frequencyBinCount);
    const volumeCallback = () => {
      analyser.getByteFrequencyData(volumes);
      const volumeSum = volumes.reduce((sum, vol) => sum + vol);
      const averageVolume = volumeSum / volumes.length;
      setVolume(averageVolume);
    };
    audioAnalyserIntervalRef.current = setInterval(volumeCallback, 100);
  };

  const stopAudioAnalyse = () => {
    clearInterval(audioAnalyserIntervalRef.current);
  };

  useEffect(() => {
    audioTrackRef.current = audioTrack;

    if (audioTrack) {
      analyseAudio(audioTrack);
    } else {
      stopAudioAnalyse();
    }
  }, [audioTrack]);
  // End Audio Analyser

  const width = step((volume / 256) * 100, 5);
  return (
    <VolumeBar sx={{ width: `${width}%` }} />
  )
}

export default React.memo(VolumeMeter);