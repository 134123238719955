import { CHAIN } from "@tonconnect/protocol";
import { Address, beginCell, toNano, SenderArguments as TonSenderArguments } from "ton-core";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import cfg from "../config";
const myAddress = cfg.tonWalletAddress;

export type SenderArguments = Omit<TonSenderArguments, "to" | 'value' | 'body'> & {
  //to: string;
  value: string;
  transactionId: string;
};

interface Sender {
  readonly address?: Address;
  send(args: SenderArguments): Promise<void>;
}

export function useTonConnect(): {
  sender: Sender;
  connected: boolean;
  wallet: string | null;
  network: CHAIN | null;
} {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();

  return {
    sender: {
      send: async (args: SenderArguments) => {
        const body = beginCell()
          .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
          .storeStringTail(args.transactionId) // write our text comment
          .endCell();

        await tonConnectUI.sendTransaction({
          messages: [
            {
              address: Address.parse(myAddress).toRawString(),
              amount: toNano(args.value).toString(),
              payload: body.toBoc().toString("base64"),
            },
          ],
          validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
        });
      },
    },
    connected: !!wallet?.account.address,
    wallet: wallet?.account.address ?? null,
    network: wallet?.account.chain ?? null,
  };
}