import React, { createContext, useCallback, useEffect, useState } from 'react';
import * as api from '../../api/account';
import { useAuth } from './AuthProvider';



type AccountContextType = {
  ballance: number;
  payment: (data: api.PaymentData) => Promise<void>;
  subscribe: (data: api.SubscribeProps) => Promise<void>;
  upgrage: (data: api.SubscribeProps) => Promise<void>;
  unsubscribe: (data: api.UnSubscribeProps) => Promise<void>;
  setAutoRenew: (modelId: string, autoRenew: boolean) => Promise<void>;
}

const AccountContext = createContext<AccountContextType>({
  ballance: 0,
  payment: () => { throw new Error('AccountContext is not defined'); },
  subscribe: () => { throw new Error('AccountContext is not defined'); },
  upgrage: () => { throw new Error('AccountContext is not defined'); },
  unsubscribe: () => { throw new Error('AccountContext is not defined'); },
  setAutoRenew: () => { throw new Error('AccountContext is not defined'); },
});

export type AccountProviderProps = {
  children: React.ReactNode;
};

export const AccountProvider: React.FC<AccountProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const [ballance, setBallance] = useState<number>(0);

  useEffect(() => {
    user && api.fetchBallance()
      .then(setBallance)
      .catch(console.error)
  }, [user]);

  /*
  const handleResetAccount = useCallback(async () => {
    const balance = await api.buy(initDataRaw!, { amount: -ballance }, alias)
    setBallance(balance);
  }, [alias, initDataRaw, ballance]);

  const handleBuy = useCallback(async (amount: number) => {
    const ballance = await api.buy(initDataRaw!, { amount }, alias);
    setBallance(ballance);
  }, [alias, initDataRaw]);
  */
  const payment = useCallback(async (data: api.PaymentData) => {
    const { ballance } = await api.payment(data);
    setBallance(ballance);
  }, []);

  const subscribe = useCallback(async (data: api.SubscribeProps) => {
    await api.subscribe(data);
  }, []);

  const upgrage = useCallback(async (data: api.SubscribeProps) => {
    await api.upgrade(data);
  }, []);

  const unsubscribe = useCallback(async (data: api.UnSubscribeProps) => {
    await api.unsubscribe(data);
  }, []);

  const setAutoRenew = useCallback(async (modelId: string, autoRenew: boolean) => {
    await api.setAutoRenew(modelId, autoRenew);
  }, []);

  return (
    <AccountContext.Provider value={{
      ballance,
      payment,
      subscribe,
      unsubscribe,
      upgrage,
      setAutoRenew,
    }}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  return React.useContext(AccountContext);
};