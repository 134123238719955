import React, { createContext, useContext, useState, useEffect } from 'react';
import * as api from '../../api/model'; 
import useSWR from 'swr';
import { Model } from '../../types/model';

// Define the company context
interface ModelContextType {
  modelId?: string;
  model?: Model;
  isOnline?: boolean;
}

// Create the company context
const ModelContext = createContext<ModelContextType | undefined>(undefined);

// Create the CompanyProvider component
interface ModelProviderProps {
  alias: string;
  children: React.ReactNode;
}

const ModelProvider: React.FC<ModelProviderProps> = ({ alias, children }) => {
  const { data: model } = useSWR<Model>(alias ? api.utrls.GetByAlias(alias) : null);
  const [isOnline, setIsOnline] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (model?.id) {
      api.getModelStatus(model.id).then(status => {
        setIsOnline(status.isOnline);
      }).catch(error => {
        console.error('Failed to fetch model status:', error);
      });
    }
  }, [model?.id]);

  return (
    <ModelContext.Provider value={{
      modelId: model?.id,
      model,
      isOnline
    }}>
      {children}
    </ModelContext.Provider>
  );
};

// Custom hook to access the company context
const useModel = (): ModelContextType => {
  const context = useContext(ModelContext);

  if (!context) {
    // throw new Error('useModel must be used within a ModelProvider');
    return {}
  }

  return context;
};

export { ModelProvider, useModel };
