import React, { useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button
} from '@mui/material';
import { useAccount } from '../providers/AccountProvider';
import { toast } from "react-hot-toast";
import { useSubLevels } from '../../hooks/useSubLevels';
import { SubLevel } from '../../types/sub';
import SubLevelSelect from '../containers/SubLevelSelect';
import { useMyModelSub } from '../../hooks/useSubs';

function useSelectedSubLevel(modelId: string) {
  const { sub } = useMyModelSub(modelId);
  const { subLevels: _subLevels } = useSubLevels();
  const subLevels = useMemo(() => {
    if(!sub) return [];
    const foundIndex = _subLevels.findIndex((sl) => sl.id === sub.levelId);
    return _subLevels.slice(foundIndex + 1);
  }, [_subLevels, sub]);

  const [subLevel, setSubLevel] = useState<SubLevel | undefined>(subLevels.find((sl) => sl.default) || subLevels[subLevels.length - 1]);
  useEffect(() => {
    if(subLevels.length === 0) return;
    setSubLevel(subLevels.find((sl) => sl.default) || subLevels[subLevels.length - 1]);
  }, [open, subLevels]);

  return { subLevel, setSubLevel };
}

function useDiscount(modelId: string) {
  const { sub } = useMyModelSub(modelId);
  const { subLevels } = useSubLevels();
  const discount = useMemo(() => {
    if(!sub) return undefined;
    const found = subLevels.find((sl) => sl.id === sub.levelId);
    return found ? found.price : 0;
  }, [subLevels, sub]);

  return discount;
}

function useExclude(modelId: string) {
  const { sub } = useMyModelSub(modelId);
  const { subLevels } = useSubLevels();
  const exclude = useMemo(() => {
    if(!sub) return [];
    const foundIndex = subLevels.findIndex((sl) => sl.id === sub.levelId);
    return subLevels.slice(0, foundIndex + 1);
  }, [subLevels, sub]);

  return exclude;
}

interface PaymentDialogProps {
  modelId: string;
  open: boolean;
  onSubscribed: () => void;
  onClose: () => void;
}


const SubscribeDialog: React.FC<PaymentDialogProps> = ({ modelId, open, onClose, onSubscribed }) => {
  const { sub } = useMyModelSub(modelId);

  const { subLevel, setSubLevel } = useSelectedSubLevel(modelId);
  const discount = useDiscount(modelId);
  const exclude = useExclude(modelId);

  const { ballance, subscribe, upgrage } = useAccount();

  const [waiting, setWaiting] = useState(false);
  const handleSubscribe = async () => {
    if(!subLevel) return;
    setWaiting(true);
    try {
      await subscribe({ modelId, levelId: subLevel.id });
      toast.success("Subscribed successfully");
      onSubscribed();
    } catch (e) {
      console.error(e);
      toast.error("Failed to subscribe");
    }
    setWaiting(false);
  }

  const handleUpgrade = async () => {
    if(!subLevel) return;
    setWaiting(true);
    try {
      await upgrage({ modelId, levelId: subLevel.id });
      toast.success("Upgraded successfully");
      onSubscribed();
    } catch (e) {
      console.error(e);
      toast.error("Failed to upgrade");
    }
    setWaiting(false);
  }

  const notEnough = useMemo(() => {
    if(!subLevel) return false;
    return ballance < subLevel.price - (discount || 0);
  }, [ballance, discount, subLevel]);

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>
        Subsribe
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <SubLevelSelect
          value={subLevel}
          onSelected={(box) => setSubLevel(box)}
          discount={discount}
          exclude={exclude}
        />
        <Button onClick={onClose} color="error" variant='contained' fullWidth size='large' sx={{ mt: 4 }} disabled={waiting}>
          Cancel
        </Button>
        <Button
          onClick={sub?.levelId ? handleUpgrade : handleSubscribe}
          color="primary" variant='contained' fullWidth size='large'
          disabled={waiting || !subLevel || notEnough}
        >
          {
            sub?.levelId ? 
              `Upgrade ${notEnough ? '(not enough balance)' : ''}` :
              `Subscribe ${notEnough ? '(not enough balance)' : ''}`
          }
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default SubscribeDialog;