import React, { useCallback, useState } from "react";
import GoldButton, { GoldButtonProps } from '../../dumps/GoldButton';
import { useModel } from "../../providers/ModelProvider";
import * as api from '../../../api/meeting';
import RequestCallDialog from "../../dialogs/RequestCallDialog";

export type VideoCallButtonProps = Omit<GoldButtonProps, 'onClick'>;

const VideoCallButton: React.FC<VideoCallButtonProps> = ({ disabled, ...props }) => {
  const { modelId, isOnline } = useModel();
  const [open, setOpen] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [requested, setRequested] = useState(false);

  const handleRequest = useCallback(async () => {
    if (!modelId) return;
    setWaiting(true);
    try {
      const room = await api.requestMeeting(modelId);
      setOpen(false);
      setRequested(true);
    } catch (error) {
      console.error(error);
    }
    setWaiting(false);
  }, [modelId]);

  const handleCancel = useCallback(() => setOpen(false), []);
  // const handleWaitingCancel = useCallback(() => setWaiting(false), []);

  return (
    <>
      <GoldButton
        variant='contained'
        fullWidth
        size='large'
        disabled={disabled || requested || waiting || !isOnline}
        onClick={() => setOpen(true)}
        {...props}
      >
        {!requested ? 'Video Call' : 'Requested'}
      </GoldButton>
      <RequestCallDialog
        open={open}
        onRequested={handleRequest}
        onCancel={handleCancel}
      />
      {/*
      <RequestWaitingDialog
        open={waiting}
        onCancel={handleWaitingCancel}
      />
      */}
    </>
  );
}

export default VideoCallButton;
